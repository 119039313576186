import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';

const currentUser = (state: RootState) => state.applicationState.user;
const currentOwner = (state: RootState) => state.acceptanceCheckState.ownedBy;

export const isOwner = createSelector(
  [ currentUser,
    currentOwner ],
  (currentUser: UserDetails, currentOwner: UserDetails) : boolean => {
    
    if (!currentOwner)
        return false;

    return currentOwner.userId === currentUser.userId;
  }
)