import * as React from 'react';

declare namespace UsersName {
    export interface Props {
      user: UserDetails;
      currentUser?: UserDetails;
      noUserText?: string;
      youText?: string;
    }

    export interface State {
    }
}

export class UsersName extends React.Component<UsersName.Props, UsersName.State> {
  render() {
    const { user, currentUser } = this.props
    let { noUserText, youText } = this.props;

    if (!youText) {
      youText = 'You';
    }

    if (!noUserText) {
      noUserText = 'No-one';
    }

    if (!user)
    {
        return noUserText;
    }

    if (currentUser && user.userId === currentUser.userId) {
      return youText;
    }

    return user.fullName;
  }
}
