import * as React from 'react';
import * as CheckActions from 'checkspa/actions/check';
import { ViewerBlock } from './ViewerBlock';
import { PlusIcon, Minus, Times } from 'library/icons';

declare namespace ImageViewer {
  export interface Props {
    actions?: typeof CheckActions;
    blocks?: Array<DgdPreviewBlock>;
    blockSelected?: string;
    dgdImage?: DgdPreviewImage;
    pageNumber?: number;
    totalPages?: number;
    showBlockControls?: boolean;
    containerWidth: number;
  }

  export interface State {
    mouseEvents: DgdPreviewMouseEvents;
    pressKeyMessage: boolean;
    touchEvents: DgdPreviewTouchEvents;
    zoomPositioning?: DgdPreviewZoomPositioning;
  }
}

export class ImageViewer extends React.Component<ImageViewer.Props, ImageViewer.State> {
  constructor(props) {
    super(props)

    this.state = {
      mouseEvents: {} as DgdPreviewMouseEvents,
      touchEvents: {
        currentX: 0,
        currentY: 0
      } as DgdPreviewTouchEvents,
      zoomPositioning: {} as DgdPreviewZoomPositioning,
      pressKeyMessage: false as boolean
    }
  }

  parentElement: HTMLDivElement;
  childElement: HTMLDivElement;

  // TODO: If this component gets updated heavily in the future try to refactor this so that we can use hooks OR getDerviedStateFromProps
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.blockSelected !== nextProps.blockSelected ) {
      this.focusBlock(nextProps);
    }
  }

  componentDidMount() {
    const { parentElement } = this

    // DOM Event Listeners for mouse, touch and zoom on parentElement; CSS rules applied to childElement
    parentElement.addEventListener("mousedown", this.handleMouseDown)
    parentElement.addEventListener("mousemove", this.handleMouseMove)
    parentElement.addEventListener("wheel", this.handleWheel)
    parentElement.addEventListener("mouseleave", this.mouseOut)

    parentElement.addEventListener("touchstart", this.handleTouchStart, { passive: true })
    parentElement.addEventListener("touchmove", this.handleTouchMove, { passive: false })
    parentElement.addEventListener("touchend", this.handleTouchEnd)
    parentElement.addEventListener("gesturechange", this.handleGestureChange)

    if (this.props.blockSelected) {
      this.focusBlock(this.props)
    }
  }

  componetWillUnmount() {
    const { parentElement } = this

    // Remove DOM Event Listeners once the component is removed
    parentElement.removeEventListener("mousedown", this.handleMouseDown)
    parentElement.removeEventListener("mousemove", this.handleMouseMove)
    parentElement.removeEventListener("wheel", this.handleWheel)
    parentElement.removeEventListener("mouseout", this.mouseOut)

    parentElement.removeEventListener("touchstart", this.handleTouchStart)
    parentElement.removeEventListener("touchmove", this.handleTouchMove)
    parentElement.removeEventListener("touchend", this.handleTouchEnd)
    parentElement.removeEventListener("gesturechange", this.handleGestureChange)
  }

  handleMouseDown = (event) => {
    this.setState({
      ...this.state,
      mouseEvents: {
        dragStart: {
          animate: false,
          posX: event.offsetX,
          posY: event.offsetY
        }
      }
    })
  }

  handleMouseMove = (event) => {
    const originalX = this.state.zoomPositioning.x ? this.state.zoomPositioning.x : 0,
          originalY = this.state.zoomPositioning.y ? this.state.zoomPositioning.y : 0

    if ( event.buttons > 0 ) {
      const { posX, posY } = this.state.mouseEvents.dragStart

      this.setState({
        zoomPositioning: {...this.state.zoomPositioning,
          x: (originalX + event.offsetX - posX ),
          y: (originalY + event.offsetY - posY),
          animate: false
        }
      });
    }
  }

  // Scroll wheel event
  handleWheel = (event) => {
    // Display a message if the user scrolls without pressing the control key
    this.setState({
      ...this.state,
      pressKeyMessage: !event.ctrlKey
    })

    if (event.ctrlKey) {
      event.preventDefault();

      if (event.deltaY < -1) {
        this.zoomIn()
      }

      if (event.deltaY > 1) {
        this.zoomOut()
      }
    }
  }

  mouseOut = (event) => {
    this.setState({...this.state,
      pressKeyMessage: false
    })
  }

  /*
   * Touch gesture functions
   */

  // Get the start point for the finger movement
  handleTouchStart = (event) => {
    const { touchEvents, zoomPositioning } = this.state,
          { pageX, pageY } = event.touches[0]

    this.setState({
      touchEvents: { ...touchEvents,
        startX: pageX,
        startY: pageY
      },
      zoomPositioning: { ...zoomPositioning,
        animate: false
      }
    })
  }

  // Called when the user's finger moves
  handleTouchMove = (event) => {
    event.preventDefault();

    let moveX = event.touches[0].pageX,
        moveY = event.touches[0].pageY,
        { touchEvents, zoomPositioning } = this.state,
        newPositionX = touchEvents.currentX + -(moveX - touchEvents.startX),
        newPositionY = touchEvents.currentY + -(moveY - touchEvents.startY);

    this.setState({
      touchEvents: { ...touchEvents,
        newPositionX: newPositionX,
        newPositionY: newPositionY,
      },
      zoomPositioning: { ...zoomPositioning,
        x: -newPositionX,
        y: -newPositionY
      }
    });
  }

  // Called when the user's finger stops moving
  handleTouchEnd = (event) => {
    let touchEvents = this.state.touchEvents;

    this.setState({
      touchEvents: {...touchEvents,
        currentX: touchEvents.newPositionX,
        currentY: touchEvents.newPositionY
      }
    });
  }

  // 'gesturechange' is a two finger gesture on iOS, ie: pinch to zoom
  handleGestureChange = (event) => {
    // TODO: Fix jankiness on guesturestart
    // TODO: Chrome/Android support
    event.preventDefault();

    let zoomPositioning = this.state.zoomPositioning,
        newZoomLevel = zoomPositioning.zoomLevel * (event.scale / zoomPositioning.zoomLevel);

    this.setState({
      zoomPositioning: {...zoomPositioning,
        newZoomLevel: newZoomLevel,
        zoomLevel: newZoomLevel
      }
    })
  }

  /*
   * Functions to zoom in/out of the DGD
   */

  resetZoom() {
    this.setState({
      zoomPositioning: { ...this.state.zoomPositioning,
        animate: true,
        x: 0,
        y: 0,
        zoomLevel: 1
      }
    })
  }

  zoomIn() {
    const { zoomPositioning } = this.state,
          originalZoom = zoomPositioning.zoomLevel ? zoomPositioning.zoomLevel : 1

    this.setState({
      zoomPositioning: {
        ...zoomPositioning,
        animate: true,
        x: zoomPositioning.x ? zoomPositioning.x : 0,
        y: zoomPositioning.y ? zoomPositioning.y : 0,
        zoomLevel: originalZoom < 2 ? (originalZoom + 0.1) : originalZoom
      }
    });
  }

  zoomOut() {
    const { zoomPositioning } = this.state,
          originalZoom = zoomPositioning.zoomLevel ? zoomPositioning.zoomLevel : 1

    this.setState({
      zoomPositioning: {
        ...zoomPositioning,
        animate: true,
        x: zoomPositioning.x ? zoomPositioning.x : 0,
        y: zoomPositioning.y ? zoomPositioning.y : 0,
        zoomLevel: originalZoom > 0.6 ? (originalZoom - 0.1) : originalZoom
      }
    });
  }

  /*
   * Functions for the DGD data blocks
   */
  focusBlock(nextProps) {
    const { clientHeight, clientWidth } = this.childElement,
          { dgdImage } = this.props,
          { height: dgdHeight, width: dgdWidth } = dgdImage

    let zoomLevel = 1,
        offsetX = 0,
        offsetY = 0

    return nextProps.blocks.map( (block, blockIndex) =>
      block.rect.map( (rect, rectIndex) => {

        if (block.pageNumber === nextProps.pageNumber && block.id === nextProps.blockSelected) {

          offsetX = ( clientWidth / dgdWidth) * -((rect.left + ( rect.width / 2 )) - ( dgdWidth / 2 ))
          offsetY = ( clientHeight / dgdHeight) * -(rect.top -  (rect.height / 2) - (this.parentElement.clientHeight) )

          if ( clientHeight > clientWidth ) {
            // Portrait
            zoomLevel = dgdWidth / rect.width
          } else {
            // Landscape
            zoomLevel = dgdHeight / rect.height
          }

          return this.setState({
            ...this.state,
            zoomPositioning: {
              animate: true,
              x: offsetX,
              y: offsetY,
              zoomLevel: zoomLevel
            }
          })
        } else {
          return ( null )
        }
      })
    )
  }



  // This is needed to prevent Firefox from selecting the image.
  draggableFunction(event) {
    return false;
  }

  render() {
    const { actions, blocks, dgdImage, blockSelected, containerWidth } = this.props,
          { zoomPositioning, pressKeyMessage } = this.state

    let imgUrl = dgdImage.url as string;

    if (imgUrl && imgUrl.indexOf('?') > -1) {
      imgUrl = imgUrl.substr(0, imgUrl.indexOf('?'));
    }

    return (
      <div ref={ element => this.parentElement = element }
        className='image-viewer image-viewer-parent'
        draggable={false}>
        {/* The following is displayed when the user scrolls with a mouse whilst hovering without pressing the control key */}
        { pressKeyMessage &&
          <div className='shift-key-message'>
            <p>Press the Control key to zoom whilst scrolling</p>
          </div>
        }

        {/* Zoom in/out/reset controls */}
        <ul className="image-viewer-controls">
          <li>
            <button onClick={() => this.zoomIn() } className="btn btn-zoom btn-zoom-first" title="Zoom In"><PlusIcon height={15} width={15} /></button>
          </li>
          <li>
            <button onClick={() => this.resetZoom() } className="btn btn-zoom btn-zoom-middle" title="Reset Zoom"><Times height={15} width={15} /></button>
          </li>
          <li>
            <button onClick={() => this.zoomOut() } className="btn btn-zoom btn-zoom-last" title="Zoom Out"><Minus height={15} width={15} /></button>
            </li>
        </ul>

        {/* The actual image in a movable wrapper */}
        <div ref={ element => this.childElement = element }
          className='image-viewer-child'
          style={{
            transform: `scale( ${zoomPositioning.zoomLevel ? zoomPositioning.zoomLevel : 1} )
                        translateX( ${zoomPositioning.x ? zoomPositioning.x : 0}px )
                        translateY( ${zoomPositioning.y ? zoomPositioning.y : 0}px )`,
            transition: zoomPositioning.animate ? "transform 0.5s" : ''
          }}>
          <img
            alt=""
            draggable={false}
            onDragStart={(e) => this.draggableFunction(e) }
            src={ imgUrl }
            height={ dgdImage.height }
            width={ dgdImage.width }
          />

          {/* When the 'Zoom into scan' button is clicked... */}
          {
            blocks.map( (block, blockIndex) =>
              block.rect.map( (rect, rectIndex) => block.id === blockSelected && block.pageNumber == this.props.pageNumber &&
                <ViewerBlock
                  actions={actions}
                  blocks={blocks}
                  blockIndex={blockIndex}
                  key={rectIndex}
                  rect={rect}
                  showBlockControls={ this.props.showBlockControls }
                  ratio={ containerWidth / dgdImage.width }
                />
              )
            )
          }
        </div>
      </div>
    )
  }
}
