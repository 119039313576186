import * as React from "react";

declare interface PackageCounterStore {
  [rowId: string]: number;
}

declare type CounterAction =
  | { type: "INCREASE"; payload: { rowId: string } }
  | { type: "DECREASE"; payload: { rowId: string } };

declare interface PackageCounterState {
  increaseRowId: (rowId: string) => void;
  decreaseRowId: (rowId: string) => void;
  store: PackageCounterStore;
}

function counterReducer(state: PackageCounterStore, action: CounterAction) {
  const rowId = action.payload.rowId;
  let currentCount = state[rowId] || 0;
  switch (action.type) {
    case "INCREASE":
      return {
        ...state,
        [rowId]: currentCount + 1,
      };
    case "DECREASE":
      if (currentCount == 0) return state;

      return {
        ...state,
        [rowId]: currentCount - 1,
      };
  }
}

const PackageCounterContext = React.createContext<
  [PackageCounterStore, React.Dispatch<CounterAction>] | undefined
>(undefined);

function PackageCounterProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const reducerArr = React.useReducer(counterReducer, {});

  return (
    <PackageCounterContext.Provider value={reducerArr}>
      {children}
    </PackageCounterContext.Provider>
  );
}

function usePackageCounter(rowId: string) {
  const context = React.useContext(PackageCounterContext);
  if (context === undefined) {
    throw new Error(
      "usePackageCounter must be used within a PackageCounterProvider"
    );
  }

  const [state, dispatch] = context;
  const increaseCount = React.useCallback((e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    dispatch({ type: "INCREASE", payload: { rowId } });
  }, [dispatch, rowId]);

  const decreaseCount = React.useCallback((e?: React.MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    dispatch({ type: "DECREASE", payload: { rowId } });
  }, [dispatch, rowId]);

  return {
    count: state[rowId] | 0,
    increaseCount,
    decreaseCount,
  };
}

function usePackageCounterTotal() {
  const context = React.useContext(PackageCounterContext);
  if (context === undefined) {
    throw new Error(
      "usePackageCounter must be used within a PackageCounterProvider"
    );
  }

  const [state, _] = context;
  const keys = Object.keys(state);
  return keys.length == 0
    ? 0
    : keys.map((key) => state[key]).reduce((total, count) => total + count);
}

export { PackageCounterProvider, usePackageCounter, usePackageCounterTotal };
