import { Actions } from "checkspa/constants";
import { AnyAction, combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { acceptanceCheckSyncCompletedReducer } from "./acceptance-check-sync-completed";
import acceptanceCheckReport from "./acceptanceCheckReport";
import acceptanceCheckState from "./acceptanceCheckState";
import apiUrls from "./apiurls";
import applicationState from "./applicationstate";
import autoCheckResponse from "./auto-check-response";
import dgdResources from "./dgd-resources";
import dgdData from "./dgddata";
import dgdViewerStatus from "./dgdviewerstatus";
import dgrReferenceStatus from "./dgrreferencestatus";
import questionToValidationLinks from "./questiontovalidationlinks";
import rightSidebar from "./rightSidebar";
import supportingData from "./supportingdata";
import userSelections from "./userSelections";
import userSettingData from "./userSettingData";
import userHints from "./userhints";
import userNotes from "./usernotes";

export interface RootState {
  id: string;
  checkBaseUrl: string;
  // Acceptance Check & DGD
  acceptanceCheckState: AcceptanceCheckState;
  dgdData: DgdData;
  userSelections: UserSelections;
  userHints: UserHints;
  userNotes: UserNotes;
  dgdResources: DgdResources;
  autoCheckResponse: AutoCheckResponse;
  acceptanceCheckReport: AcceptanceCheckReport;

  // User permissions & Settings
  applicationState: ApplicationState;
  userSettingData: UserSettingData;

  // SPA interaction state
  dgdViewerStatus: DgdViewerStatus;
  dgrReferenceStatus: DgrReferenceStatus;
  rightSidebar: RightSidebarState;

  // Supporting data
  supportingData: SupportingData;
  questionToValidationLinks: QuestionToValidationLink[];
  apiUrls: ApiUrls;
  dgSyncMap: DangerousGoodAsyncMap;
  features: FeatureFlags;
}

const initialState = null;

const noOpReducer = handleActions<string | any>({}, initialState);

const combinedReducers = combineReducers<RootState>({
  id: noOpReducer,
  checkBaseUrl: noOpReducer,
  // Acceptance Check & DGD
  acceptanceCheckState,
  dgdData,
  userSelections,
  userHints,
  userNotes,
  dgdResources,
  autoCheckResponse: autoCheckResponse,
  acceptanceCheckReport,

  // User permissions & Settings
  applicationState,
  userSettingData,

  // SPA interaction state
  dgdViewerStatus,
  dgrReferenceStatus,
  rightSidebar,

  // Supporting data
  supportingData,
  apiUrls,
  questionToValidationLinks,
  dgSyncMap: (state, initialState) => state || initialState,
  features: (state, initialState) => state || initialState,
});

export default (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case Actions.AC_SYNC_COMPLETED:
      return acceptanceCheckSyncCompletedReducer(state, action as any);
    default:
      return combinedReducers(state, action);
  }
};
