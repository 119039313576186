import { handleActions } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: RightSidebarState = null;

export default handleActions<RightSidebarState, any>({
  [Actions.DGR_VIEWER_SUCCESS]: (state, action) => {
    return {...state,
      show: true,
      activeTab: 'dgr',
    } as RightSidebarState
  },
  [Actions.RIGHT_SIDEBAR_TOGGLE]: (state, action) => {
    var { payload } = action;

    return {...state,
      show: payload.show
    } as RightSidebarState
  },

  [Actions.DGD_VIEWER_FOCUS]: (state, action) => {
    return {...state,
      show: true,
      activeTab: 'dgd',
    } as RightSidebarState
  },

  [Actions.RIGHT_SIDEBAR_TAB_CHANGE]: (state, action) => {
    var {payload} = action;

    return {...state,
      activeTab: payload.activeTab,
      show: true
    } as RightSidebarState
  },

  [Actions.RIGHT_SIDEBAR_CHANGE_WIDTH]: (state, action) => {
    var {payload} = action;

    return {...state,
      dockSidebarWidth: payload.dockSidebarWidth
    } as RightSidebarState
  },

  [Actions.RIGHT_SIDEBAR_DOCK_TOGGLE]: (state, action) => {
    var {payload} = action;

    return {...state,
      dockSidebar: payload.dockSidebar
    } as RightSidebarState
  },

  [Actions.DGD_PREVIEW_AREA_TOGGLE]: (state, action) => {
    const areaName = action.payload;

    // When already open in that area, then close
    if (state.previewArea == areaName) {
      return {...state,
        previewArea: null,
        activeTab: 'dgd',
        show: false
      } as RightSidebarState
    }

    return {...state,
      previewArea: areaName,
      activeTab: 'preview',
      show: true
    } as RightSidebarState
  },

  [Actions.DGD_PREVIEW_CLOSE]: (state, action) => {
    return {...state,
      previewArea: null,
      activeTab: 'dgd',
      show: false
    } as RightSidebarState
  }
}, initialState);
