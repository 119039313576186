import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { dgdMenuItemStatusesForPackagingArea, dgdMenuItemStatusesForDocumentationArea } from './dgd-menu-item-status';

const areaSummary = (itemStatuses: { [key: string]: DgdMenuItemStatus }) => {
  var itemKeys = Object.keys(itemStatuses);
    var completedSections = itemKeys
      .map(key => itemStatuses[key].status)
      .filter(w => w !== null)
      .length;

    return {
      total: itemKeys.length,
      completed: completedSections
    }
}

export const documentationAreaSummary = createSelector(
  [ dgdMenuItemStatusesForDocumentationArea ],
  ( itemStatuses: { [key: string]: DgdMenuItemStatus }
  ) : { total: number, completed: number } => {
    return areaSummary(itemStatuses);
  }
)

export const packagingAreaSummary = createSelector(
  [ dgdMenuItemStatusesForPackagingArea ],
  ( itemStatuses: { [key: string]: DgdMenuItemStatus }
  ) : { total: number, completed: number } => {
    return areaSummary(itemStatuses);
  }
)