import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';

const switchChecklistView = createAction<ChecklistView>(Actions.SWITCH_CHECKLIST_VIEW);

export function switchTabsListView(listType: ChecklistView): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(switchChecklistView(listType))
  }
}
