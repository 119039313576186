import * as React from 'react';
import { AircraftTypeFormatted, ShipmentTypeFormatted } from 'library/form/components';

declare namespace AcHeaderInformation {
  export interface Props {
    dgdData: DgdData;
    acceptanceCheckState: AcceptanceCheckState;
    autocheckResponse: AutoCheckResponse
    currentUser: UserDetails;
  }

  export interface State {
    /* empty */
  }
}

export class AcHeaderInformation extends React.Component<AcHeaderInformation.Props, AcHeaderInformation.State> {

  render() {
    const { dgdData, acceptanceCheckState, autocheckResponse, currentUser } = this.props;

    var dgdHeaderStates = dgdData.stateCodes
      .map(stateCode => {
        const parentStateCode = autocheckResponse && autocheckResponse.parentStateCodes ? autocheckResponse.parentStateCodes[stateCode] : null;
        return parentStateCode ? `${stateCode} (${parentStateCode})` : stateCode;
      })
      .map( (state, index) => {
          return index > 0 ? ', ' + state : state
      })

    var dgdHeaderOperators = dgdData.operatorCodes.map( (operator, index) => {
        return index > 0 ? ', ' + operator : operator
    })

    return (
        <div className="check-top-tabs">
            <div className={"grid-wrapper"}>
                <dl className={"dl-horizontal"}>
                    {
                        acceptanceCheckState.documentationStartedOn &&
                        <div>
                            <dt>Checklist</dt>
                            <dd>
                                { acceptanceCheckState.checklistName }
                            </dd>
                        </div>
                    }
                    <div>
                        <dt>States
                        </dt>
                        <dd>
                            {dgdHeaderStates}
                        </dd>
                    </div>
                    <div>
                        <dt>Operator
                        </dt>
                        <dd>
                            {dgdHeaderOperators}
                        </dd>
                    </div>
                    <div>
                        <dt>Aircraft type</dt>
                        <dd>
                            <AircraftTypeFormatted aircraftType={ dgdData.aircraftType } />
                        </dd>
                    </div>
                    <div>
                        <dt>Shipment type
                        </dt>
                        <dd>
                            <ShipmentTypeFormatted shipmentType={ dgdData.shipmentType } />
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
  }
}
