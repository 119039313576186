import * as React from 'react';
import * as classNames from 'classnames';
import { Link } from 'react-router-dom';
import { UrlTemplates } from 'checkspa/constants';
import * as H from 'history';

declare namespace AcHeaderTabs {
  export interface Props {
    location?: H.Location;
    applicationState: ApplicationState;
    acceptanceCheckState: AcceptanceCheckState;
    packagingAreaSummary: { total: number, completed: number };
    documentationAreaSummary: { total: number, completed: number };
    verificationStepSummary: VerificationStepSummary;
    documentationCheckOnly: boolean;
    dgdSourceType: DgdSourceType;
    isOwner: boolean;
    dgdCanBeVerified: boolean;
  }

  export interface State {
    /* empty */
  }
}

export class AcHeaderTabs extends React.Component<AcHeaderTabs.Props, AcHeaderTabs.State> {

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { location,
      applicationState,
      acceptanceCheckState,
      dgdCanBeVerified } = this.props;

    let currentPath = ''

    if (location && location.pathname)
      currentPath = location.pathname;

    const documentationTab = this.documentationTabDetails(currentPath);
    const signOffTab = this.signOffTabDetails(currentPath);
    const packagingTab = this.packagingTabDetails(currentPath);

    var reportingTabClasses = classNames({
      'active': currentPath.indexOf('/report') > -1
    })

    return (
        <div className="check-top-tabs">
            <ul className={ "nav-tabs-2 grid-wrapper" }>
                { dgdCanBeVerified ? this.renderVerificationTab(currentPath) : this.renderXsdgTab(currentPath) }
                {
                  !applicationState.isThirdPartyValidator && !applicationState.isDgDigitalSubscription &&
                  <>
                    <li className={ documentationTab.classes } data-test='documentation-tab'>
                      <Link to={ documentationTab.url } onClick={ documentationTab.onClick } title={ documentationTab.title }>
                        { documentationTab.text }
                      </Link>
                    </li>
                    { packagingTab.visible &&
                      <li className={ packagingTab.classes } data-test='packaging-tab'>
                        <Link to={ UrlTemplates.packagingTemplate.expand({id: "overview"}) } onClick={ packagingTab.onClick } title={ packagingTab.title }>
                          { packagingTab.text }
                        </Link>
                      </li>
                    }
                    {
                      acceptanceCheckState.signedOffBy ?
                      <li className={ reportingTabClasses } data-test='report-tab'>
                        <Link to={ UrlTemplates.reportUrl } title={ signOffTab.title }>
                          Report ({ acceptanceCheckState.signedOffResult })
                        </Link>
                      </li>
                      :
                      <li className={ signOffTab.classes } data-test='sign-off-tab'>
                        <Link to={ UrlTemplates.signOffTemplate.expand({}) } onClick={ signOffTab.onClick } title={ signOffTab.title }>
                          Sign Off
                        </Link>
                      </li>
                    }
                  </>
                }
            </ul>
        </div>
    );
  }

  renderXsdgTab(currentPath: string) {
    var xsdgClasses = classNames({
      'active': currentPath == UrlTemplates.xsdgUrl
    }, "")

    return (
      <li className={ xsdgClasses } data-test='xsdg-tab'>
        <Link to={ UrlTemplates.xsdgUrl }>
          DGD
        </Link>
      </li>
    );
  }

  renderVerificationTab(currentPath: string) {
    const verificationTab = this.verificationTabDetails(currentPath);

    return (
      <li className={ verificationTab.classes } data-test='verification-tab'>
        <Link to={ UrlTemplates.verificationTemplate.expand({}) }>
          Verification ({verificationTab.completed} / {verificationTab.total})
        </Link>
      </li>
    );
  }

  verificationTabDetails(currentPath: string) {
    const {
      acceptanceCheckState,
      verificationStepSummary } = this.props;

    const verificationTabActive = currentPath.indexOf('/verification') > -1 || currentPath.indexOf('/verify') > -1;
    var verificationClasses = classNames({
      'active': verificationTabActive
    }, "")

    return {
      tabActive: verificationTabActive,
      total: verificationStepSummary.allSteps.length,
      completed: verificationStepSummary.allSteps.filter(w => w.validatedOn).length,
      classes: verificationClasses
    };
  }

  documentationTabDetails(currentPath: string) {
    const {
      acceptanceCheckState,
      documentationAreaSummary,
      applicationState,
      dgdCanBeVerified } = this.props;

    const documentationTabActive = currentPath.indexOf('/documentation') > -1;
    let documentationTitle = '';

    let documentationAreaDisabled = !acceptanceCheckState.verificationCompletedOn;
    if (!dgdCanBeVerified) {
       // XSDG/EDGD DGDs can go straight into the documentation check
      documentationAreaDisabled = false;
    }
    if (documentationAreaDisabled) {
      documentationTitle = 'Verification must be completed before document check can begin';
    }

    var identificationClasses = classNames({
      'active': documentationTabActive,
      'disabled': documentationAreaDisabled
    }, "")

    let documentationUrl = UrlTemplates.documentationSetup;
    if (acceptanceCheckState.documentationStartedOn) {
      documentationUrl = UrlTemplates.documentationTemplate.expand({id: "general"});
    }

    return {
      url: documentationUrl,
      tabActive: documentationTabActive,
      tabDisabled: documentationAreaDisabled,
      onClick: this.interceptClickForDisabled(documentationAreaDisabled),
      title: documentationTitle,
      classes: identificationClasses,
      text: documentationAreaSummary.total ? `Documentation (${documentationAreaSummary.completed} / ${documentationAreaSummary.total})` : 'Documentation'
    };
  }

  signOffTabDetails(currentPath: string) {
    const { isOwner, applicationState } = this.props;

    const signOffTabActive = currentPath.indexOf('/signoff') > -1;
    const signOffIsDisabled = !isOwner || !applicationState.canAcceptanceCheck;
    let signOffTitle = '';
    if (signOffIsDisabled) {
      signOffTitle = 'Documentation must be completed before you can view the sign off screen';
    }
    var signOffClasses = classNames({
      'active': signOffTabActive,
      'disabled': signOffIsDisabled
    }, "")

    return {
      tabActive: signOffTabActive,
      tabDisabled: signOffIsDisabled,
      onClick: this.interceptClickForDisabled(signOffIsDisabled),
      title: signOffTitle,
      classes: signOffClasses
    };
  }

  packagingTabDetails(currentPath: string) {
    const {
      packagingAreaSummary,
      acceptanceCheckState,
      documentationCheckOnly } = this.props;

    const packagingTabActive = currentPath.indexOf('/packaging') > -1;
    const packagingIsDisabled = !acceptanceCheckState.documentationCompletedOn;
    var packagingClasses = classNames({
      'active': packagingTabActive,
      'disabled': packagingIsDisabled
    }, "")

    var packagingText = 'Packaging';
    var packagingTitle = 'Documentation check must be completed before packaging can be started'
    if (acceptanceCheckState.documentationCompletedOn) {
      packagingTitle = '';
      packagingText = `Packaging (${packagingAreaSummary.completed} / ${packagingAreaSummary.total})`;
    }

    return {
      tabActive: packagingTabActive,
      tabDisabled: packagingIsDisabled,
      title: packagingTitle,
      text: packagingText,
      classes: packagingClasses,
      visible: !documentationCheckOnly,
      onClick: this.interceptClickForDisabled(packagingIsDisabled),
    };
  }

  interceptClickForDisabled = (isDisabled: boolean) => {
    const { acceptanceCheckState } = this.props;

    return (e) => {
      if (isDisabled) {
        if (!acceptanceCheckState.documentationCompletedOn) {
          e.preventDefault();
        }
      }
    }
  }
}
