import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';

const dgdRowsFromState = (state: RootState) => state.dgdData.dangerousGoods;

export const dgdRowsForDocumentationArea = createSelector(
  [dgdRowsFromState],
  (dgdRows: DangerousGoodPackage[]) => {
    return dgdRows;
  }
)

export const dgdRowsForPackagingArea = createSelector(
  [dgdRowsFromState],
  (dgdRows: DangerousGoodPackage[]) => {
    return dgdRows.filter(row => !row.parentId || row.parentId == null);
  }
)