import * as React from 'react';
import { UrlTemplates } from 'checkspa/constants';

declare namespace Breadcrumbs {
  export interface Props {
    dgdData: DgdData;
    dgdLibraryUrl: string;
  }

  export interface State {
    /* empty */
  }
}

export class Breadcrumbs extends React.Component<Breadcrumbs.Props, Breadcrumbs.State> {

  render() {
    const { dgdData, dgdLibraryUrl } = this.props;

    return (
        <ol className="breadcrumb autocheck-breadcrumb">
            <li><a href={ UrlTemplates.homeTemplate() }>Home</a></li>
            <li><a href={ dgdLibraryUrl }>DGD Library</a></li>
            <li className="active">{dgdData.referenceType}: {dgdData.reference}</li>
        </ol>
    );
  }
}
