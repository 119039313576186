import { Action } from 'redux-actions';
import { RootState } from 'checkspa/reducers';

export const acceptanceCheckSyncCompletedReducer = (state: RootState, action: Action<AcSyncResult>) => {

  if (action.payload) {
    return applyNewEntityMapping(state, action.payload);
  }

  return state;
};

const clone = <T>(obj: T) => {
  return JSON.parse(JSON.stringify(obj));
}

const requiresNoSync = (obj: Object) => {
  return !obj || Object.keys(obj).length == 0;
}

function applyNewEntityMapping(rootState : RootState, entityMap : AcSyncResult) {

  // The state is immutable, so we create new instances of whatever we will be modifying
  // Always create a new RootState and set the AcceptanceCheckState
  rootState = {
    ...rootState,
    acceptanceCheckState: {
      ...rootState.acceptanceCheckState,
      ...entityMap.acceptanceCheckState
    },
    dgSyncMap: entityMap.declaredGoods
  };

  if (entityMap.apiUrls) {
    // Update the API URLs
    rootState.apiUrls = {
      ...entityMap.apiUrls,
      // URLs that never change
      dgrOfflineContentUrl: rootState.apiUrls.dgrOfflineContentUrl,
      dgdLookupUrls: rootState.apiUrls.dgdLookupUrls,
    }

    // Now update all the dgd & section image URLs
    rootState.dgdData = {
      ...rootState.dgdData,
      dgdImages: entityMap.dgdImages,
      sectionImages: entityMap.dgdSectionImages
    }
  }

  if (requiresNoSync(entityMap.declaredGoods) &&
      requiresNoSync(entityMap.answers) &&
      requiresNoSync(entityMap.resources)) {
      return rootState;
  }

  applyNewRowIds(rootState, entityMap.declaredGoods);
  applyAnswers(rootState, entityMap.answers);
  applyResourceUrls(rootState, entityMap.resources);

  return rootState;
}

function applyAnswers(rootState: RootState, answerMap: Record<string, AnswerAsyncMap>) {
  if (!answerMap ||
      Object.keys(answerMap).length === 0 ||
      !rootState.userSelections ||
      rootState.userSelections.length === 0) {
    return;
  }

  rootState.userSelections = rootState.userSelections
    .map(answer => {
      var updateAnswer = answerMap[answer.deleteAnswerUrl];
      if (!updateAnswer) {
        return answer;
      }

      return {
        ...answer,
        deleteAnswerUrl: updateAnswer.deleteAnswerUrl,
      } as UserSelection
    });
}

function applyResourceUrls(rootState: RootState, itemMap: DgdResourcesDictionary) {
  if (!itemMap ||
      Object.keys(itemMap).length === 0 ||
      !rootState.dgdResources ||
      !rootState.dgdResources.resources ||
      rootState.dgdResources.resources.length === 0) {
    return;
  }

  rootState.dgdResources = {
    ...rootState.dgdResources,
    resources: rootState.dgdResources.resources
      .map(resource => {
        var updatedResource = itemMap[resource.acceptanceCheckResourceId];
        if (!updatedResource){
          return resource;
        }

        return {
          ...resource,
          acceptanceCheckResourceId: updatedResource.acceptanceCheckResourceId,
          editUrl: updatedResource.editUrl,
          deleteUrl: updatedResource.deleteUrl,
          downloadUrl: updatedResource.downloadUrl
        } as DgdResource
      })
  };
}

function applyNewRowIds(rootState: RootState, declaredGoodsMap: DangerousGoodAsyncMap) {

  if (!declaredGoodsMap || Object.keys(declaredGoodsMap).length === 0) {
      return;
  }

  var dgPackages = rootState.dgdData.dangerousGoods.map(dg => {
    var newDetails = declaredGoodsMap[dg.id];
    var parentDetails = declaredGoodsMap[dg.parentId];

    if (!newDetails && !parentDetails) {
      return dg;
    }

    return {
      ...dg,
      id: newDetails.rowId,
      editUrl: newDetails.editUrl,
      deleteUrl: newDetails.deleteUrl,
      parentId: parentDetails ? parentDetails.rowId : dg.parentId // If parentId has changed
    } as DangerousGoodPackage;
  });

  rootState.dgdData = {
    ...rootState.dgdData,
    dangerousGoods: dgPackages
  };

  // While it would be better to only update the items that need updating, it's far simpler and easier to read if we just clone everything
  // And then update any items inline
  rootState.userSelections = clone(rootState.userSelections);
  rootState.userNotes = clone(rootState.userNotes);
  rootState.userHints = clone(rootState.userHints);
  for (var key of Object.keys(declaredGoodsMap)) {
    var newDetails = declaredGoodsMap[key];

    // Update Answers
    for (var answer of rootState.userSelections.filter(w => w.rowId == key)) {
      answer.rowId = newDetails.rowId;
    }

    // Update Notes
    for (var note of rootState.userNotes.filter(w => w.rowId == key)) {
      note.rowId = newDetails.rowId;
    }

    // Update Hints
    for (var hint of rootState.userHints.filter(w => w.rowId == key)) {
      hint.rowId = newDetails.rowId;
    }

    // Update the acceptance check results
    if (rootState.autoCheckResponse) {
      rootState.autoCheckResponse = clone(rootState.autoCheckResponse);
      var autoCheckResponse = rootState.autoCheckResponse;
      updateChecklistSection(autoCheckResponse.documentation, key, newDetails);
      updateChecklistSection(autoCheckResponse.packaging, key, newDetails);

      for (var packingDetails of autoCheckResponse.outerPackagingDetails.filter(w => w.rowId == key)) {
          packingDetails.rowId = newDetails.rowId;
      }

      for (var userHint of autoCheckResponse.userHintInformation.filter(w => w.rowId == key)) {
          userHint.rowId = newDetails.rowId;
      }
    }
  }
}

function updateChecklistSection(area: CheckAreaForRows, oldRowId: string, rowDetails: {editUrl: string, deleteUrl: string, rowId: string}) {
  if (!area) {
      return;
  }

  var areaForRow = area[oldRowId];
  if (!areaForRow) {
      return;
  }

  // Set the new key
  delete area[oldRowId];
  area[rowDetails.rowId] = areaForRow;

  // Now update all children
  areaForRow.rowId = rowDetails.rowId;
  for (var section of areaForRow.sections) {
      section.rowId = rowDetails.rowId;

      for (var question of section.questions) {
          question.rowId = rowDetails.rowId;
      }
  }
}
