import { RuleOutput, SectionStatus } from 'checkspa/constants/index';

export const inferSectionStatus = function(questions: CheckQuestionForRow[], questionsMap: ChecklistQuestionMap, getAnswerFor: UserSelectionFromRuleAndRowId) : SectionStatusSummary {
  let status = null;
  let questionNotAnswered = false;
  let containsFailures = false;
  let sectionSaving = false;
    // This should be the number of automated failures that have not been overriden by the user
  let automatedFailures = 0;

  for (let questionForRow of questions) {
    let userAnswer = getAnswerFor(questionForRow.questionId, questionForRow.rowId);
    var checklistQuestion = questionsMap[questionForRow.questionId];
    var defaultResult = questionForRow.ignoreChecklistDefault ? null : checklistQuestion.defaultResult;

    if (questionForRow.automatedResult == RuleOutput.NO && (!userAnswer || userAnswer.value == null))
    {
      automatedFailures++;
      containsFailures = true;
    }

    if ((userAnswer && userAnswer.value == RuleOutput.NO))
    {
      containsFailures = true;
    }

    if ((!userAnswer || userAnswer.value == null) && !questionForRow.automatedResult && !defaultResult ) {
      questionNotAnswered = true;
    }

    if (userAnswer && userAnswer.saving) {
      sectionSaving = true;
    }
  }

  if (!questionNotAnswered)
    status = containsFailures ? SectionStatus.FAILED : SectionStatus.PASSED;

  return {
    status: status,
    automatedFailures: automatedFailures,
    saving: sectionSaving
  } as SectionStatusSummary;
}
