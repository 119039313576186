import { handleActions, Action } from 'redux-actions';
import * as Actions from '../constants/actions';
import { RootState } from '.';

const initialState = null;

export default handleActions<DgdData | any>({
  [Actions.AC_POLLING_STATUS_UPDATE]:  (state: DgdData , action: Action<Partial<RootState>>) => {
    if (!action.payload.dgdData) {
      return state;
    }

    return {
      ...state,
      ...action.payload.dgdData
    } as DgdData;
  },
  [Actions.SAVE_REORDER_ROWS_SUCCESS]: (state, action) => {
    if (action.payload && action.payload.declaredGoods) {
      var rows = action.payload.declaredGoods as DangerousGoodPackage[];
      return {...state, dangerousGoods: rows};
    }

    return state;
  },
  [Actions.SAVE_REORDER_ROWS_FAIL]: (state, action) => {

    return state;
  },
  [Actions.SAVE_ROW_REQUESTED]: (state, action: Action<DangerousGoodPackage>) => {

    return setRowActionInProgress(state, action.payload, 'UPDATING', true);
  },
  [Actions.SAVE_ROW_FAIL]: (state, action: Action<{ response: jsendResponse, row: DangerousGoodPackage}>) => {

    return setRowActionInProgress(state, action.payload.row, null, true);
  },
  [Actions.SAVE_ROW_SUCCESS]: (state, action) => {
    if (action.payload && action.payload.row) {
      var row = action.payload.row as DangerousGoodPackage;
      var existingRow = state.dangerousGoods.find(w => w.id == row.id);

      if (!existingRow) {
        console.debug("Item does not exist", row);
        return state;
      }

      return {
        ...state,
        dangerousGoods: state.dangerousGoods.map(dg => {
          if (dg === existingRow) {
            return {
              ...dg,
              ...row,
              actionInProgress: null,
              hasUnsavedChanges: false
            };
          }

          return dg;
        })
      }
    }

    return state;
  },
  [Actions.ADD_ROWS_SUCCESS]: (state, action) => {
    if (action.payload && action.payload.newRows) {
      var rows = action.payload.newRows as DangerousGoodPackage[];
      return {...state, dangerousGoods: [...state.dangerousGoods, ...rows]};
    }

    return state;
  },
  [Actions.DELETE_ROW_REQUESTED]: (state, action) => {
    if (action.payload) {
      const dgRow = action.payload as DangerousGoodPackage;

      return setRowActionInProgress(state, dgRow, 'DELETING', dgRow.hasUnsavedChanges);
    }

    return state;
  },
  [Actions.DELETE_ROW_FAIL]: (state, action: Action<{ error: jsendResponse, row: DangerousGoodPackage }>) => {
    if (action.payload) {

      const dgRow = action.payload.row;
      return setRowActionInProgress(state, dgRow, null, dgRow.hasUnsavedChanges);
    }

    return state;
  },
  [Actions.DELETE_ROW_SUCCESS]: (state, action) => {
    if (action.payload) {
      const data = action.payload as { deletedRow: DangerousGoodPackage, affectedRows: DangerousGoodPackage[]};

      var affectedRows = data.affectedRows.reduce((map, row : DangerousGoodPackage) => {
        map[row.id] = row;
        return map;
      }, {});

      const newDgArr = state.dangerousGoods
        .filter(w => w.id != data.deletedRow.id)
        .map(row => {
          if (affectedRows[row.id])
            return affectedRows[row.id];

          return row;
        });

      return {...state, dangerousGoods: newDgArr};
    }

    return state;
  },
  [Actions.VALIDATE_PROPERTY_REQUEST]: (state: DgdData, action) => {
    if (action.payload) {
      const data = action.payload as ValidateDgdPropertyRequest;

      var newState = {...state};

      data.properties.forEach(data => {
        var propertyMeta = newState[data.field + 'Meta'] as DgdValueMeta;

        var newPropertyMeta = {...propertyMeta};
        newPropertyMeta.tempValue = state[data.field];

        newState[data.field + 'Meta'] = newPropertyMeta;
        if (data.field == 'aircrafttype')
          newState.aircraftTypeSv = null;
        if (data.field == 'shipmenttype')
          newState.shipmentTypeSv = null;
      });

      return newState;
    }
  },
  [Actions.VALIDATE_PROPERTY_CONFIRMED]: (state: DgdData, action) => {
    if (action.payload && action.payload.dgdData) {
      const dgdData = action.payload.dgdData as DgdData;

      var dgdDataWithSv = {
        ...dgdData,
        shipmentTypeSv: state.shipmentTypeSv,
        aircraftTypeSv: state.aircraftTypeSv
      }
      return {
        ...state,
        ...dgdDataWithSv
      };
    }

    return state;
  },
  [Actions.VALIDATE_PROPERTY_ERROR]: (state: DgdData, action) => {
    if (action.payload) {
      const data = action.payload as ValidateDgdPropertyRequest;

      var newState = {...state};

      data.properties.forEach(data => {
        const propertyMeta = newState[data.field + 'Meta'] as DgdValueMeta;
        var newPropertyMeta = {...propertyMeta};

        newPropertyMeta.tempValue = null; // reset the tempValue to null
        newState[data.field] = propertyMeta.tempValue; // Set to the old value
        newState[data.field + 'Meta'] = newPropertyMeta;
      });

      return state;
    }
  },
  [Actions.VALIDATE_DGD_REQUEST]: (state: DgdData, action: { payload: { rows: DangerousGoodPackage[] } }) => {
    if (action.payload.rows) {
      const rowIds = action.payload.rows.map(m => m.id);
      return {
        ...state,
        dangerousGoods: state.dangerousGoods.map(row => {
          if (rowIds.indexOf(row.id) > -1) {
            return {
              ...row,
              hasUnsavedChanges: true,
              actionInProgress: "UPDATING"
            } as DangerousGoodPackage
          }

          return row;
        })
      }
    }
  },
  [Actions.VALIDATE_DGD_CONFIRMED]:  (state: DgdData , action) => {
    const payload = action.payload as { dgdData: DgdData, declaredGoods: DangerousGoodPackage[]  };

    return {
      ...state,
      ...payload.dgdData,
      dangerousGoods: payload.declaredGoods,
    } as DgdData;
  },
  [Actions.VALIDATE_DGD_ERROR]:  (state: DgdData , action) => {
    const payload = action.payload as jsendResponse;

    if (payload.status == 'error') {
      alert(payload.message);
    }

    return state;
  },
  [Actions.DOCUMENTATION_SETUP_SUCCESS]:  (state: DgdData , action) => {
    const payload = action.payload as { dgdData: DgdData };

    return {
      ...state,
      ...payload.dgdData
    } as DgdData;
  },
}, initialState);

function setRowActionInProgress(state: DgdData, dgRow: DangerousGoodPackage, actionInProgress: DgdRowAction, hasUnsavedChanges: boolean) {
  var dangerousGoods = state.dangerousGoods.map(w => {
    if (w.id === dgRow.id) {
      return {
        ...dgRow,
        actionInProgress,
        hasUnsavedChanges
      } as DangerousGood;
    }
    return w;
  });

  return {
    ...state,
    dangerousGoods: dangerousGoods
  };
}

