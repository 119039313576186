import * as React from 'react';
import { FormattedMessage } from 'react-intl';


declare namespace AircraftTypeFormatted {
    export interface Props {
      aircraftType: AircraftType;
    }

    export interface State {
    }
}

export default (props: AircraftTypeFormatted.Props) => {
  if (!props.aircraftType) {
    return <>-</>;
  }

  return <FormattedMessage id={ "aircrafttype." + props.aircraftType } defaultMessage={ props.aircraftType } />
}
