import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';
import { goToPage } from 'environment/go-to-page';

const ownershipRequested = createAction(Actions.CHANGE_OWNERSHIP_REQUESTED);
const ownershipTaken = createAction<AcceptanceCheckState>(Actions.CHANGE_OWNERSHIP_SUCCESS);
const ownershipFailed = createAction(Actions.CHANGE_OWNERSHIP_FAILED);

export function changeOwnership( url: string, httpVerb: HttpVerb ): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(ownershipRequested( ));

    asyncRequest({
      url: url,
      method: httpVerb,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(ownershipTaken(response.data as AcceptanceCheckState));
          return;
        }

        dispatch(ownershipFailed());
        if (response.status != 'unauthorised') {
          alert('There was a problem changing ownership. Please refresh the page and try again.');
        }
      }
    });
  }
}

export const closeOwnershipPopup = createAction(Actions.CLOSE_OWNERSHIP_POPUP);

const checkOwnershipRequested = createAction<OwnershipCallback>(Actions.CHECK_OWNERSHIP_REQUESTED);
const checkOwnershipSuccess = createAction<AcceptanceCheckState>(Actions.CHECK_OWNERSHIP_SUCCESS);
const checkOwnershipFailed = createAction(Actions.CHECK_OWNERSHIP_FAILED);

export function checkOwnership( checkOwnershipUrl: string, ownershipCallback?: OwnershipCallback): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(checkOwnershipRequested(ownershipCallback));

    asyncRequest({
      url: checkOwnershipUrl,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(checkOwnershipSuccess(response.data));
          return;
        }

        dispatch(checkOwnershipFailed());
        if (response.status != 'unauthorised') {
          alert('There was a problem changing ownership. Please refresh the page and try again.');
        }
      }
    });
  }
}

export function releaseDgdAndGoToLibrary( checkOwnershipUrl: string, goToDgdLibrary: string ): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    asyncRequest({
      url: checkOwnershipUrl,
      callback: function(response: jsendResponse) {

        var json = response.data;
        var ownershipActions = json.ownershipActions as OwnershipActionButton[];
        var releaseOwnership = ownershipActions.find(w => w.httpVerb === 'DELETE');

        if (!releaseOwnership) {
          dispatch(checkOwnershipSuccess(json)); // Release button not found, so show the popup
          return;
        }

        asyncRequest({
          url: releaseOwnership.actionUrl,
          method: releaseOwnership.httpVerb,
          callback: function(response: jsendResponse) {
            if (response.status == 'success') {
              goToPage(goToDgdLibrary);
              return;
            }

            alert('There was a problem releasing control. Please refresh the page to try again.');
          }
        });
      }
    });
  }
}
