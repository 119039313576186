import { Action } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState: AcceptanceCheckReport = null;

const acceptanceCheckReportReducer = (state: AcceptanceCheckReport = initialState, action: Action<any>) => {

  switch (action.type) {
    case Actions.REPORT_REQUESTED:
      if (!state) {
        return {
          loadingStatus: 'LOADING'
        } as AcceptanceCheckReport
      }
      break;

    case Actions.REPORT_FAILED:
      return {
        ...state,
        loadingStatus: 'FAILED'
      } as AcceptanceCheckReport

    case Actions.REPORT_RECEIVED:
    case Actions.CHECK_SIGNOFF_CONFIRMED:
      if (action.payload && action.payload.acceptanceCheckReport) {
        return {
          ...action.payload.acceptanceCheckReport,
          loadingStatus: 'LOADED'
        } as AcceptanceCheckReport;
      }
      break;
  }

  return state;
};

export default acceptanceCheckReportReducer;
