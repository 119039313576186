import { calculateDgMenuItemStatus } from './calculate-menu-item-status';

export const getDgMenuItemStatusForRows = (sectionStatusesByRow: SectionStatusMap) : DgdMenuStatusMap => {
    let map = {} as DgdMenuStatusMap;

    const keys = Object.keys(sectionStatusesByRow);

    const getAndSetStatusFor = (rowId: string) => {
      const sectionStatuses = sectionStatusesByRow[rowId];

      map[rowId] = calculateDgMenuItemStatus(sectionStatuses);
    };

    for (var key of keys) {
      getAndSetStatusFor(key);
    }

    return map;
}