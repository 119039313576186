import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';

const dgrReferenceLoading = createAction(Actions.DGR_VIEWER_LOADING);
const dgrReferenceSuccess = createAction<DgrReferenceStatus>(Actions.DGR_VIEWER_SUCCESS);
const dgrReferenceError = createAction<CheckLink>(Actions.DGR_VIEWER_ERROR);
const dgrReferenceCancel = createAction(Actions.DGR_VIEWER_CANCEL);

export function displayReference(link: CheckLink, sectionUrlTemplate: string): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    const url = sectionUrlTemplate.replace('{section}', link.ref);

    dispatch(dgrReferenceLoading())

    asyncRequest({
      url: url,
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
      callback: function(response: jsendResponse) {
        if (response.status != 'success') {
          dispatch(dgrReferenceError(link))
        } else {
          var reference = {
            id: link.ref,
            title: response.data.title,
            body: response.data.html,
            type: link.type
          } as DgrReferenceStatus;

          dispatch(dgrReferenceSuccess(reference))
        }
      }
    });
  }
}

export function hideReference(): (dispatch: Dispatch<ReduxActions.Action<void>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<void>>) => {
    dispatch(dgrReferenceCancel())
  }
}
