import { App } from "checkspa/App";
import { acceptanceCheckSyncCompleted } from "checkspa/actions/check";
import { RootState } from "checkspa/reducers";
import { configureStore } from "checkspa/store";
import Router from "environment/Router";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { IntlProvider, defineMessages } from "react-intl";
import { Provider } from "react-redux";
import { PackageCounterProvider } from "./ui/components/PackageCounter/package-counter-context";

// TODO: Revert back to simply being "const data = window.INITIAL_STATE;"
// once tablet is released and enforced
const data = {
  ...window.INITIAL_STATE,
  features: window.INITIAL_STATE.features || {},
};

__webpack_public_path__ = window.resourceBaseUrl;

if (data) {
  var applicationState = {
    ...data.applicationState,
    ownershipPopup: "Closed",
    sendByEmailStatusMap: {},
  } as ApplicationState;

  var initialData: RootState = {
    id: data.id,
    checkBaseUrl: data.checkBaseUrl,
    // Acceptance Check & DGD
    acceptanceCheckState: data.acceptanceCheckState as AcceptanceCheckState,
    dgdData: {
      ...data.dgdData as DgdData,
      dgdCanBeVerified: typeof data.dgdData.dgdCanBeVerified === 'undefined' // backward compatibility
          ? data.dgdData.dgdSourceType !== 'Xsdg'
          : data.dgdData.dgdCanBeVerified
    },
    userSelections: data.userSelections,
    userHints: data.userHints,
    userNotes: data.userNotes,
    dgdResources: { sections: [], resources: [] } as DgdResources, // Loaded dynamically as the user needs it
    autoCheckResponse: data.autoCheckResponse as AutoCheckResponse,
    acceptanceCheckReport: data.acceptanceCheckReport as AcceptanceCheckReport,
    features: {
      ...(data.features as FeatureFlags),
      awbVerificationRequirement:
        data.features.awbVerificationRequirement || "none",
      awbNumberFormats: data.features.awbNumberFormats || [],
    },

    // User permissions & Settings
    applicationState: applicationState,
    userSettingData: {
      checklistView: "tabs",
      dgdResourceEditing: false,
      dgdResourceLoadingStatus: false,
      location: data.userSettingData.location,
      timezones: data.userSettingData.timezones,
      operatorReviewFilters: {
        defaultResults: true,
        defaultNoResults: true,
        defaultNaResults: true,
        autoCheckPass: true,
        autoCheckFail: true,
        autoCheckNa: true,
      } as RuleTypeVisibility,
    } as UserSettingData,

    // SPA interaction state
    dgdViewerStatus: {
      visible: data.dgdData.dgdImages && data.dgdData.dgdImages.length > 0,
      pageNumber: 1,
    } as DgdViewerStatus,
    dgrReferenceStatus: {} as DgrReferenceStatus,
    rightSidebar: {
      show: false,
      activeTab: null,
      dockSidebarWidth: 700, // Should this come from user settings per chance?
      dockSidebar: false, // Should this come from user settings per chance?
      previewArea: null,
    } as RightSidebarState,

    // Supporting data
    supportingData: data.supportingData as SupportingData,
    apiUrls: data.apiUrls,
    questionToValidationLinks: [
      {
        resultIdentifier: "FullNameAndAddressOfShipper",
        dgdPropertyName: "shipper",
        previewArea: "shipper",
        title: "Shipper",
      },
      {
        resultIdentifier: "FullNameAndAddressOfConsignee",
        dgdPropertyName: "consignee",
        previewArea: "consignee",
        title: "Consignee",
      },
      {
        resultIdentifier: "AirwayBillNumber",
        dgdPropertyName: "awb",
        previewArea: "docref",
        title: "Air Waybill Number",
      },
      {
        resultIdentifier: "FullNameOfAirportCityForDeparture",
        dgdPropertyName: "origin",
        previewArea: "departure",
        title: "Departure",
      },
      {
        resultIdentifier: "FullNameOfAirportCityForDestination",
        dgdPropertyName: "destination",
        previewArea: "destination",
        title: "Destination",
      },
      {
        resultIdentifier: "AdditionalHandlingDoesNotExist",
        dgdPropertyName: "additionalHandlingInformation",
        previewArea: "additionalhandlinginformation",
        title: "Additional Handling Information",
      },
      {
        resultIdentifier: "",
        dgdPropertyName: "aircraftType",
        previewArea: "transportdetails",
        title: "Aircraft & Shipment Type",
      },
    ],
    dgSyncMap: {},
  };

  /*
  data.userSettingData.operatorReviewFilters.forEach(filter => {
    switch (filter) {
      case 1 << 0:
        initialData.userSettingData.operatorReviewFilters.defaultResults = true
        break;
      case 1 << 1:
        initialData.userSettingData.operatorReviewFilters.defaultNoResults = true
        break;
      case 1 << 2:
        initialData.userSettingData.operatorReviewFilters.defaultNaResults = true
        break;
      case 1 << 3:
        initialData.userSettingData.operatorReviewFilters.autoCheckPass = true
        break;
      case 1 << 4:
        initialData.userSettingData.operatorReviewFilters.autoCheckFail = true
        break;
      case 1 << 5:
        initialData.userSettingData.operatorReviewFilters.autoCheckNa = true
        break;
      default:
        break;
    }
  });
  */
}

const store = configureStore(initialData);

const messages = defineMessages(data.intlMessages);

window.fromCsharp = {
  syncCompleted: (syncEntityMap: AcSyncResult) => {
    store.dispatch(acceptanceCheckSyncCompleted(syncEntityMap));
  },
  requestResponse: null,
};

const rootEl = document.getElementById("root");

const ProviderAndApp = () => (
  <IntlProvider locale="en" defaultLocale="en" messages={messages}>
    <Provider store={store}>
      <PackageCounterProvider>
        <Router>
          <App />
        </Router>
      </PackageCounterProvider>
    </Provider>
  </IntlProvider>
);

if (rootEl) {
  ReactDOM.render(<ProviderAndApp />, rootEl);
}
