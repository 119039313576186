import { handleActions, Action } from 'redux-actions';
import * as Actions from '../constants/actions';

const initialState : DgdViewerStatus = null;

export default handleActions<DgdViewerStatus, DangerousGoodPackage | number | ViewDgdBlockPayload>({

  [Actions.DGD_VIEWER_FOCUS]: (state, action: Action<DangerousGoodPackage>) => {
    const { id, ocrLocation } = action.payload;
    let pageNumber = 1;

    if (ocrLocation && ocrLocation.blocks && ocrLocation.blocks.length > 0) {
      pageNumber = ocrLocation.blocks[0].pageNumber;
    }

    return {
      ...state,
      blockSelected: id,
      pageNumber: pageNumber
    } as DgdViewerStatus
  },

  [Actions.DGD_VIEWER_FOCUS_ID]: (state, action: Action<ViewDgdBlockPayload>) => {
    const { id, pageNumber } = action.payload;

    return {
      ...state,
      blockSelected: id,
      pageNumber: pageNumber
    } as DgdViewerStatus
  },

  [Actions.DGD_VIEWER_CANCEL]: (state, action) => {
    return {
      ...state,
      blockSelected: null
    } as DgdViewerStatus
  },

  [Actions.DGD_VIEWER_CHANGE_PAGE]: (state, action: Action<number>) => {
    return {
      ...state,
      blockSelected: null,
      pageNumber: action.payload
    }
  },
}, initialState);
