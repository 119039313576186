import * as React from 'react';

declare namespace Times {
  export interface Props {
    height?: number;
    width?: number;
  }

  export interface State {
  }
}

export class Times extends React.PureComponent<Times.Props, Times.State> {
  render() {
    const height = this.props.height ? this.props.height : 16;
    const width = this.props.width ? this.props.width : 16;

    return(
      <svg width={width} height={height} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"/></svg>
    )
  }
}

