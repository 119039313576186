import * as React from 'react';
import { Dialog } from 'library/form/components';
import { ExclamationMark, Refresh } from 'library/icons';
import { FormattedMessage } from 'react-intl';
import { FormattedRelative } from 'checkspa/ui/components/FormattedRelative';
import { UsersName } from 'checkspa/ui/components';

declare namespace OwnershipPopup {
  export interface Props {
    changeOwnership: (url: string, method: HttpVerb) => void;
    closePopup: () => void;
    acceptanceCheckState: AcceptanceCheckState;
    applicationState: ApplicationState;
  }

  export interface State {
    launchPopup: boolean;
  }
}

export class OwnershipPopup extends React.Component<OwnershipPopup.Props, OwnershipPopup.State> {

  render() {
    const { acceptanceCheckState, applicationState, changeOwnership } = this.props;
    const { ownershipActions } = applicationState;

    return (
      <>
        { applicationState.ownershipPopup !== 'Closed' &&
          <Dialog type='alert'>
            <header className="section-header"><h4>Acceptance Check Status</h4></header>

            { applicationState.ownershipPopup === 'Loading' &&
              <div className='ownership-popup-loading'>
                <div className='autocheck-dgr-viewer-loading'>
                  <span className='spin-graphic loading-graphic-container'>
                    <Refresh height={20} width={20} />
                    Loading
                  </span>
                </div>
              </div>
            }
            {
              applicationState.ownershipPopup === 'Error' &&
              <p className='bg-info'>
                  <ExclamationMark height={20} width={20} />
                  {" "}
                  An unexpected problem has occurred.
              </p>
            }
            {
              applicationState.ownershipPopup === 'Ready' &&
              <>
                <dl className="dl-vertical">
                  <div>
                    <dt>Current status</dt>
                    <dd>
                      <FormattedMessage id={ "checkstatus." + acceptanceCheckState.status } defaultMessage={ acceptanceCheckState.status } />
                    </dd>
                  </div>
                  <div>
                    <dt>Current owner</dt>
                    <dd>
                      <UsersName user={ acceptanceCheckState.ownedBy } currentUser={ applicationState.user } noUserText='No owner' />
                    </dd>
                  </div>
                  <div>
                    <dt>Last updated</dt>
                    <dd>
                      { acceptanceCheckState.lastUpdated ?
                      <FormattedRelative value={ acceptanceCheckState.lastUpdated } />
                      :
                      <>No changes made</>
                      }
                    </dd>
                  </div>
                  <div>
                    <dt>Last updated by</dt>
                    <dd>
                      <UsersName user={ acceptanceCheckState.lastUpdatedBy } currentUser={ applicationState.user } noUserText='No changes made' />
                    </dd>
                  </div>
                </dl>

                <div className={'btn-container--justify'}>
                  <button className="btn btn-secondary" onClick={ e => this.closePopup() } type="button" >Cancel</button>
                  { ownershipActions &&
                    ownershipActions
                      .filter(w => !w.isSecondary)
                      .map((btn, indx) => {
                        return (
                          <button key={ indx } className='btn btn-primary' onClick={ e => changeOwnership(btn.actionUrl, btn.httpVerb) }>
                            { btn.text }
                          </button>
                        );
                      })
                  }
                </div>
                { ownershipActions &&
                  ownershipActions
                    .filter(w => w.isSecondary)
                    .reverse()
                    .map((btn, indx) => {

                      return (
                        <div className={'btn-container--right-align'} key={ indx }>
                          <button className='btn btn-tertiary' onClick={ e => changeOwnership(btn.actionUrl, btn.httpVerb) }>
                            { btn.text }
                          </button>
                        </div>
                      );
                    })
                }
              </>
            }
          </Dialog>
        }
      </>
    )
  }

  closePopup() {
    this.props.closePopup();
  }
}
