import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { RuleOutput, SectionStatus } from 'checkspa/constants/index';
import { answerKey } from './answer-key';

const userAnswers = (state: RootState) => state.userSelections;

export const answersMap = createSelector(
  [ userAnswers ],
  (userAnswers: UserSelection[]) : UserSelectionMap => {
    
    let outputObj = {};

    for (const answer of userAnswers) {
      outputObj[answerKey(answer.identifier, answer.rowId)] = answer;
    }

    return outputObj;
  }
)