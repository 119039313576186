import { createAction } from 'redux-actions';
import { Actions } from 'checkspa/constants';
import { Dispatch } from 'redux';
import { runAutoCheck } from 'checkspa/actions/async/RunAutoCheck';
import { asyncRequest } from 'communication/async-request';

const setHintSaving = createAction<UserHint>(Actions.SET_HINT_SAVING);
const setHintConfirmed = createAction<UserHint>(Actions.SET_HINT_CONFIRMED);
const setHintFailed = createAction<UserHint>( Actions.SET_HINT_ERROR);

export function updateHint(userHint:UserHint, updateHintUrl: string, autoCheckStatus: RunAutoCheckStatus): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(setHintSaving(userHint));

    var body = {
      hintIdentifier: userHint.hintIdentifier,
      rowId: userHint.rowId,
      value: userHint.value
    };

    asyncRequest({
      url: updateHintUrl,
      method: 'POST',
      data: body,
      callback: function(response: jsendResponse) {
        if (response.status == 'success') {
          dispatch(setHintConfirmed(userHint));
          runAutoCheck(autoCheckStatus)(dispatch);
        } else {
          dispatch(setHintFailed(userHint));
          if (response.status != 'unauthorised') {
            alert('A problem occured when saving your hint, it has been reset back to the previous value.');
          }
        }
      }
    });
  }
}
