import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';
import { runAutoCheck } from './RunAutoCheck';

const documentationSetupRequest = createAction(Actions.DOCUMENTATION_SETUP_REQUEST);
const documentationSetupSuccess = createAction<AcceptanceCheckState>(Actions.DOCUMENTATION_SETUP_SUCCESS);
const documentationSetupError = createAction(Actions.DOCUMENTATION_SETUP_ERROR);

export declare interface DocumentationCheckSetupApiInput
{
  checklistReference: string;
  useDefaultAnswers: boolean;
  airWaybill: string;
  operatorIds: number[];
  departureId: string;
  destinationId: string;
  transitPoints: string[];
}

export function documentationSetup(documentationSetupUrl: string, setupRequest: DocumentationCheckSetupApiInput, autoCheckStatus: RunAutoCheckStatus, successCallback?: () => void): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    dispatch(documentationSetupRequest());

    asyncRequest({
      url: documentationSetupUrl,
      method: 'POST',
      data: setupRequest,
      callback: function(response: jsendResponse) {
        if (response.status != 'success') {
          dispatch(documentationSetupError(response));
        } else {
          dispatch(documentationSetupSuccess(response.data))
          runAutoCheck(autoCheckStatus, true, successCallback)(dispatch);
        }
      }
    });
  }
}
