import * as React from 'react';
import { FormattedMessage } from 'react-intl';


declare namespace ShipmentTypeFormatted {
    export interface Props {
      shipmentType: ShipmentTypeEnum;
    }

    export interface State {
    }
}

export default (props: ShipmentTypeFormatted.Props) => {
  if (!props.shipmentType) {
    return <>-</>;
  }

  return <FormattedMessage id={ "shipmenttype." + props.shipmentType } defaultMessage={ String(props.shipmentType) } />
}
