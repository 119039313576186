const initialState: AcceptanceCheckState = null;

const acceptanceCheckStateReducer = (state: AcceptanceCheckState = initialState, action: any) => {

  // Always run this in case acceptanceCheckState is returned (saves keep adding these whenever a new reducer is added)
  if (action.payload && action.payload.acceptanceCheckState) {
    var acceptanceCheckState = action.payload.acceptanceCheckState as AcceptanceCheckState;
    return {
      ...state,
      ...acceptanceCheckState // Latest owner details etc
    };
  }

  return state;
};

export default acceptanceCheckStateReducer;
