import * as React from 'react';
import { UsersName } from 'checkspa/ui/components';
import { FormattedRelative } from 'checkspa/ui/components/FormattedRelative';
import { NewlineToBreak } from 'library/form/components';

declare namespace ReferenceShowArea {
  export interface Props {
    questionToValidation: QuestionToValidationLink;
    dgd: DgdData;
    onClose: () => void;
    currentUser: UserDetails;
    dgdSourceType: DgdSourceType;
  }

  export interface State {
  }
}

export class ReferenceShowArea extends React.Component<ReferenceShowArea.Props, ReferenceShowArea.State> {

  render() {
    const { questionToValidation, dgd, currentUser, dgdSourceType, onClose } = this.props;

    const { value, meta, image } = this.getValidationDetails(questionToValidation, dgd);

    if (!meta) {
      return (
        <div className="reference-show-area">
          <p>
              No details to be shown
          </p>
        </div>
      );
    }

    return (
      <div className="reference-show-area">
        <div className="reference-show-area_header">
          <h2>
            { image ? "Scanned image" : "Image not found" }
          </h2>
        </div>
        { image &&
          <div className="reference-area-details">
            <img src={image.url} className="reference-area-image" />
          </div>
        }
        { value &&
          <div className="reference-validation">
            <div className="reference-validation-header">
              <h3>
                { questionToValidation.title }
                { dgdSourceType !== 'Xsdg' &&
                  <>
                    { meta.validatedOn ?
                      <span className="reference-validated">
                        { 'Validated by ' }
                        <UsersName user={ meta.validatedBy } currentUser={ currentUser } />
                        { ' ' }
                        <FormattedRelative value={ meta.validatedOn }/>
                      </span>
                      :
                      <span className="reference-unvalidated">Un-validated</span>
                    }
                  </>
                }
              </h3>
            </div>
            <div className="reference-editor-wrapper">
              <div className="reference-editor">
                <NewlineToBreak value={ value } />
              </div>
              <button type="button" className="btn btn-secondary" onClick={ onClose }>Close</button>
            </div>
          </div>
        }
      </div>
    );
  }

  getValidationDetails(questionToValidation: QuestionToValidationLink, dgd: DgdData) {
    if (!questionToValidation || !dgd)
      return null;

    const propertyName = questionToValidation.dgdPropertyName;
    let propertyValue = dgd[propertyName];
    const meta = dgd[propertyName + "Meta"] as DgdValueMeta;
    propertyValue = propertyValue ? propertyValue : '';
    const image = dgd.sectionImages.find(w => w.id === questionToValidation.previewArea);

    if ((propertyValue as AjaxLookupResult).label) {
      propertyValue = (propertyValue as AjaxLookupResult).label;
    }

    return {
      name: propertyName,
      meta: meta,
      value: propertyValue as string,
      image: image
    }
  }
}
