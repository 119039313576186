import { Action } from 'redux-actions';

export const setHintSaving = (state: UserHints, action: Action<UserHint>) => {
  const { rowId, hintIdentifier, value } = action.payload;
  
  const userHint = state.find(input => input.hintIdentifier == hintIdentifier && input.rowId == rowId);

  if (typeof userHint == 'undefined') {
    return [...state, {
      hintIdentifier: hintIdentifier,
      value: value,
      rowId: rowId,
      isSaving: true
    } as UserHint];      
  }

  return state.map(hint => {
    if (hint.hintIdentifier == hintIdentifier && hint.rowId == rowId) { 
      return {...hint,
        value: value,
        oldValue: hint.value,
        isSaving: true
      };
    }
    return hint;
  });
}