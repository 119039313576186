import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';
import { debounce } from 'lodash';

export const changeRuleVisibilityAction = createAction<RuleTypeVisibility>(Actions.CHANGE_RULE_TYPE_VISIBILITY);

export function changeRuleVisibility(saveFilterUrl: string, userSettingsData: RuleTypeVisibility): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {


    dispatch(changeRuleVisibilityAction(userSettingsData))

    saveVisibilityFilterDebounced(saveFilterUrl, userSettingsData)
  }
}

const saveVisibilityFilter = function(saveFilterUrl: string, ruleTypeVisibility: RuleTypeVisibility) {
  let sendFilterStatus = ''

  if (ruleTypeVisibility.defaultResults) {
    sendFilterStatus += `filters=${1 << 0}&`
  }
  if (ruleTypeVisibility.defaultNoResults) {
    sendFilterStatus += `filters=${1 << 1}&`
  }
  if (ruleTypeVisibility.defaultNaResults) {
    sendFilterStatus += `filters=${1 << 2}&`
  }
  if (ruleTypeVisibility.autoCheckPass) {
    sendFilterStatus += `filters=${1 << 3}&`
  }
  if (ruleTypeVisibility.autoCheckFail) {
    sendFilterStatus += `filters=${1 << 4}&`
  }
  if (ruleTypeVisibility.autoCheckNa) {
    sendFilterStatus += `filters=${1 << 5}`
  }

  asyncRequest({
    url: saveFilterUrl,
    method: 'POST',
    data: sendFilterStatus,
    headers: new Headers({
      "Content-type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest"
    }),
    addJsonHeaders: false,
    callback: function(response: jsendResponse) {
      // Do nothing?
    }
  })
}

const saveVisibilityFilterDebounced = debounce(saveVisibilityFilter, 1000);
