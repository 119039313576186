import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';

const checklistQuestions = (state: RootState) => state.autoCheckResponse.checklistQuestions;

export const checklistQuestionMap = createSelector(
  [ checklistQuestions ],
  (questions: ChecklistQuestion[]) : ChecklistQuestionMap => {
    
    let questionMap = {};

    for (const question of questions) {
      questionMap[question.id] = question;
    }

    return questionMap;
  }
)